/* eslint-disable jsx-a11y/aria-role */
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import Home from '../views/customer/home/Home';
import RefreshToken from '../components/RefreshToken';
import store from '../store';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const IndexPage = () => (
  <Provider store={store}>
    <RefreshToken>
      <Suspense fallback={loading}>
        <Home />
      </Suspense>
    </RefreshToken>
  </Provider>
);

export default IndexPage;
